import React, { useState, useRef } from "react";
import QRCode from "qrcode.react";

function App() {
  const [link, setLink] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);
  const [fileName, setFileName] = useState("");
  const qrCodeRef = useRef(null);

  const handleInputChange = (e) => {
    setLink(e.target.value);
  };

  const generateQRCode = () => {
    if (link.trim() === "") {
      alert("Please enter a link.");
      return;
    }
    setShowQRCode(true);
  };

  const updateFileName = (e) => {
    setFileName(e.target.value);
  };

  const downloadQRCode = () => {
    if (qrCodeRef.current !== null) {
      const canvas = qrCodeRef.current.querySelector("canvas");
      const qrCodeImage = canvas.toDataURL("image/png");

      const generatedFileName = fileName || generateRandomFileName();

      // Create a temporary anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = qrCodeImage;
      downloadLink.download = generatedFileName;

      // Trigger the download
      downloadLink.click();
    }
  };

  const generateRandomFileName = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result + ".png";
  };

  return (
    <div className="container">
      <h1 className="title">QR Code Generator</h1>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter link"
          value={link}
          onChange={handleInputChange}
          required
        />
        <button className="generate-button" onClick={generateQRCode}>
          Generate QR Code
        </button>
      </div>
      {showQRCode && (
        <div className="qrcode-container" ref={qrCodeRef}>
          <QRCode value={link} />
        </div>
      )}
      {showQRCode && (
        <div className="download-container">
          <input
            type="text"
            className="file-name-input"
            placeholder="Enter file name (optional)"
            value={fileName}
            onChange={updateFileName}
          />
          <button className="download-button" onClick={downloadQRCode}>
            Download QR Code
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
